import http from "./axios_client";
import { apiCaller } from "./metruyenvl_caller";

const reviewAPI = {
    async getListReview(book_id?: string, author_id?: string, offset?: number, limit?: number) {
        return apiCaller("/review", "get", {
            query: {
                book_id: book_id,
                author_id: author_id,
                offset: offset,
                limit: limit,
            },
        });
    },
    async createReview(book_id: string, content: string, score: number) {
        return apiCaller("/review", "post", {
            header: { "Authorization": "" },
            body: {
                request: {
                    book_id: book_id,
                    content: content,
                    score: score,
                }
            }
        })
    },
    async updateReview(book_id: string, review_id: string, content: string, score: number) {
        return apiCaller("/review", "put", {
            header: { "Authorization": "" },
            body: {
                request: {
                    book_id: book_id,
                    id: review_id,
                    content: content,
                    score: score,
                }
            }
        })
    },
    async deleteReview(review_id: string) {
        return apiCaller("/review/{id}", "delete", {
            header: { "Authorization": "" },
            path: {
                id: review_id,
            }
        })
    },
};

export default reviewAPI;
