import { apiCaller } from "./metruyenvl_caller";

const authorAPI = {
    async getDetail(slug?: string) {
        return apiCaller("/author/info", "get", { query: { slug: slug } });
    },

    async getAll(page: number, pageSize: number) {
        return apiCaller("/author", "get", { query: { page: page, page_size: pageSize } });
    },

    async getBooks(slug: string, page: number, pageSize: number) {
        return apiCaller("/author/books", "get", { query: { slug: slug, page: page, page_size: pageSize } });
    },
};

export default authorAPI;
