import { apiCaller } from "./metruyenvl_caller";

const commentAPI = {
    async getList(targetID: string, offset: number, limit: number) {
        return apiCaller("/comment", "get", { query: { target_id: targetID, offset: offset, limit: limit } });
    },
    async getCommentNewest(limit: number) {
        return apiCaller("/comment/newest", "get", { query: { limit: limit } });
    },

    async create(bookID: string, targetType: "author" | "book" | "review" | "chapter" | "comment" | "user", targetID: string, content: string) {
        return apiCaller("/comment", "post", {
            body: {
                request: {
                    book_id: bookID,
                    target_id: targetID,
                    content: content,
                    target_type: targetType,
                },
            },
            header: {
                Authorization: "",
            },
        });
    },

    async update(id: string, content: string) {
        return apiCaller("/comment", "put", {
            body: {
                request: {
                    id: id,
                    content: content,
                },
            },
            header: {
                Authorization: "",
            },
        });
    },

    async delete(id: string) {
        return apiCaller("/comment/{id}", "delete", {
            path: {
                id: id,
            },
            header: {
                Authorization: "",
            },
        });
    },
};

export default commentAPI;
