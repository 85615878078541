import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import bookAPI from "@/services/book_api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import bookmarkAPI from "@/services/bookmark_api";
import { definitions } from "@/services/metruyenvl";
import commentAPI from "@/services/comment_api";
import readAPI from "@/services/read_api";
import reviewAPI from "@/services/review_api";
import { RootState } from "../store";

export const LOAD_MORE_REVIEW_COMMENT_LIMIT = 10;

// Book
export const getDetailByID = createAsyncThunk("book/detail_by_id", async ({ id }: { id: string }) => {
    return await bookAPI.getDetailByID(id);
});

export const getDetailBySlug = createAsyncThunk("book/detail_by_slug", async ({ slug }: { slug: string }) => {
    return await bookAPI.getDetailBySlug(slug);
});

// Chapter
export const getListChapters = createAsyncThunk("book/chapters", async ({ slug, page, pageSize }: { slug: string; page: number; pageSize: number }) => {
    return await bookAPI.getListChaptersBySlug(slug, page, pageSize);
});

// Review
export const getListReviews = createAsyncThunk("book/reviews/get", async ({ userID, bookID, offset, limit }: { userID: string, bookID: string; offset: number; limit: number }) => {
    return await reviewAPI.getListReview(bookID, "", offset, limit);
});

export const createReview = createAsyncThunk("book/reviews/create", async ({ bookID, content, score }: { bookID: string, content: string, score: number }) => {
    return await reviewAPI.createReview(bookID, content, score);
});

export const updateReview = createAsyncThunk("book/reviews/update", async ({ bookID, reviewID, content, score }: { bookID: string, reviewID: string, content: string, score: number }) => {
    return await reviewAPI.updateReview(bookID, reviewID, content, score);
});

export const deleteReview = createAsyncThunk("book/reviews/delete", async ({ bookID, reviewID }: { bookID: string, reviewID: string }) => {
    return await reviewAPI.deleteReview(reviewID);
});

// Comments
export const getListComments = createAsyncThunk("book/comments/get", async ({ userID, bookID, offset, limit }: { userID: string, bookID: string; offset: number; limit: number }) => {
    return await commentAPI.getList(bookID, offset, limit);
});

export const createComment = createAsyncThunk("book/comments/create", async ({ bookID, targetType, targetID, content }: { bookID: string, targetType: "author" | "book" | "review" | "chapter" | "comment" | "user", targetID: string, content: string }) => {
    return await commentAPI.create(bookID, targetType, targetID, content);
});

export const updateComment = createAsyncThunk("book/comments/update", async ({ bookID, commentID, content }: { bookID: string, commentID: string, content: string }) => {
    return await commentAPI.update(commentID, content);
});

export const deleteComment = createAsyncThunk("book/comments/delete", async ({ bookID, commentID }: { bookID: string, commentID: string }) => {
    return await commentAPI.delete(commentID);
});




// Bookmark
export const getListBookmarks = createAsyncThunk("book/bookmarks", async ({ bookID, offset, limit }: { bookID: string; offset: number; limit: number }) => {
    return await bookmarkAPI.getAllByBook(bookID, offset, limit);
});

// Reaction

// Suggestion

// Reading
export const getReadingsByBook = createAsyncThunk("book/reading", async ({ bookID, chapterID, offset, limit }: { bookID: string; chapterID: string; offset: number; limit: number }) => {
    return await readAPI.getAllUserReading({ bookID, chapterID, offset, limit });
});

export type BookData = {
    isLoading: boolean
    detail: definitions["do.BookDO"]
    chapters: {
        isLoading: boolean
        paging: definitions["v_api.Paging"]
        list: definitions["do.ChapterDetailRes"][]
    }
    comments: {
        isLoading: boolean
        paging: definitions["v_api.Paging"]
        list: definitions["do.CommentDO"][]
    }
    reviews: {
        isLoading: boolean
        paging: definitions["v_api.Paging"]
        list: definitions["do.ReviewDO"][]
    }
    bookmarks: {
        isLoading: boolean
        paging: definitions["v_api.Paging"]
        list: definitions["do.BookDO"][]
    }
    reactions: {
        isLoading: boolean
        paging: definitions["v_api.Paging"]
        list: definitions["models.ReactionMD"][]
    }
    suggestions: {
        isLoading: boolean
        paging: definitions["v_api.Paging"]
        list: definitions["do.UserDO"][]
    }
    usersReading: {
        isLoading: boolean
        paging: definitions["v_api.Paging"]
        list: definitions["do.UserDO"][]
    }
    userAction: {
        isLoading: boolean
        data: definitions["do.UserActionDO"]
    }
}

function createNewBookData(): BookData {
    return {
        isLoading: true,
        detail: {} as definitions["do.BookDO"],
        chapters: {
            isLoading: false,
            paging: {} as definitions["v_api.Paging"],
            list: [] as definitions["do.ChapterDetailRes"][]
        },
        comments: {
            isLoading: false,
            paging: {} as definitions["v_api.Paging"],
            list: [] as definitions["do.CommentDO"][]
        },
        reviews: {
            isLoading: false,
            paging: {} as definitions["v_api.Paging"],
            list: [] as definitions["do.ReviewDO"][]
        },
        bookmarks: {
            isLoading: false,
            paging: {} as definitions["v_api.Paging"],
            list: [] as definitions["do.BookDO"][]
        },
        reactions: {
            isLoading: false,
            paging: {} as definitions["v_api.Paging"],
            list: [] as definitions["models.ReactionMD"][]
        },
        suggestions: {
            isLoading: false,
            paging: {} as definitions["v_api.Paging"],
            list: [] as definitions["do.UserDO"][]
        },
        usersReading: {
            isLoading: false,
            paging: {} as definitions["v_api.Paging"],
            list: [] as definitions["do.UserDO"][]
        },
        userAction: {
            isLoading: false,
            data: [] as definitions["do.UserActionDO"]
        },
    }
}

function createStorageKeyBySlug(slug: string): string {
    return "truyen:slug:" + slug
}

function createStorageKeyByID(id: string): string {
    return "truyen:id:" + id
}

const initialState = {
    map: {} as { [key: string]: BookData },
};

const bookMap = createSlice({
    name: "books",
    initialState,
    reducers: {
        initialBookStateFromStorage: (state, action: PayloadAction<string>) => { // Using slug
            const storedState = localStorage.getItem(createStorageKeyBySlug(action.payload));
            const newState = storedState ? JSON.parse(storedState) : {};

            state.map[action.payload] = newState || createNewBookData();
        },
        setBookStateToStorage: (state, action: PayloadAction<string>) => { // Using slug
            const bookSlug = action.payload;
            let book = state.map[bookSlug];

            if (!!book) {
                localStorage.setItem(createStorageKeyBySlug(book.detail.slug as string), JSON.stringify(book))
                localStorage.setItem(createStorageKeyByID(book.detail.id as string), JSON.stringify(book))
            }
        },
    },

    extraReducers(builder) {
        builder.addCase(getDetailByID.pending, (state, action) => {
            const bookID = action.meta.arg.id;
            let book = state.map[bookID];

            if (!book) {
                book = createNewBookData()
            }
            state.map[bookID] = book
        });
        builder.addCase(getDetailByID.fulfilled, (state, action) => {
            const bookID = action.meta.arg.id;
            let book = state.map[bookID];

            if (!book) {
                book = createNewBookData()
            }

            book.isLoading = false;
            book.detail = action.payload.data as definitions["do.BookDO"];

            state.map[book.detail.id as string] = book
            state.map[book.detail.slug as string] = book
        });
        builder.addCase(getDetailByID.rejected, (state, action) => {
            const bookID = action.meta.arg.id;

            let book = state.map[bookID]
            if (!!book) {
                book.isLoading = false

                state.map[book.detail.id as string] = book;
                state.map[book.detail.slug as string] = book;
            }
        });

        builder.addCase(getDetailBySlug.pending, (state, action) => {
            const bookID = action.meta.arg.slug;
            let book = state.map[bookID];

            if (!book) {
                book = createNewBookData()
            }

            state.map[bookID] = book;
        });
        builder.addCase(getDetailBySlug.fulfilled, (state, action) => {
            const bookSlug = action.meta.arg.slug;
            let book = state.map[bookSlug];

            if (!book) {
                book = createNewBookData()
            }

            book.isLoading = false;
            book.detail = action.payload.data as definitions["do.BookDO"];

            state.map[bookSlug] = book
            state.map[book.detail.id as string] = book
        });
        builder.addCase(getDetailBySlug.rejected, (state, action) => {
            const bookSlug = action.meta.arg.slug;
            let book = state.map[bookSlug]
            if (!!book) {
                book.isLoading = false

                state.map[book.detail.id as string] = book;
                state.map[book.detail.slug as string] = book;
            }
        });

        builder.addCase(getListChapters.pending, (state, action) => {
            const bookSlug = action.meta.arg.slug;
            let book = state.map[bookSlug];

            if (!book) {
                book = createNewBookData()
            }
            if (!book.chapters) {
                book.chapters = {
                    isLoading: true,
                    paging: {} as definitions["v_api.Paging"],
                    list: [] as definitions["do.ChapterDetailRes"][]
                }
            }

            state.map[book.detail.id as string] = book
            state.map[book.detail.slug as string] = book

            console.log("getListChapters.pending - book: ", JSON.stringify(book))
        });
        builder.addCase(getListChapters.fulfilled, (state, action) => {
            const bookSlug = action.meta.arg.slug;
            let book = state.map[bookSlug];

            if (!book) {
                book = createNewBookData()
            }
            if (!book.chapters) {
                book.chapters = {
                    isLoading: true,
                    paging: {} as definitions["v_api.Paging"],
                    list: [] as definitions["do.ChapterDetailRes"][]
                }
            }

            book.isLoading = false
            book.chapters.isLoading = false;
            book.chapters.list = action.payload.data as definitions["do.ChapterDetailRes"][]
            book.chapters.paging = action.payload.paging as definitions["v_api.Paging"]

            state.map[book.detail.id as string] = book
            state.map[book.detail.slug as string] = book
        });
        builder.addCase(getListChapters.rejected, (state, action) => {
            const bookSlug = action.meta.arg.slug;

            const book = state.map[bookSlug];

            console.log("getListChapters.rejected - book: ", JSON.stringify(book))
            if (!!book) {
                book.isLoading = false
                book.chapters.isLoading = false

                state.map[book.detail.id as string] = book;
                state.map[book.detail.slug as string] = book;
            }
        });

        // Review
        builder.addCase(getListReviews.pending, (state, action) => {
            const bookID = action.meta.arg.bookID;
            let book = state.map[bookID];

            if (!book) {
                book = createNewBookData()
            }
            if (!book.reviews) {
                book.reviews = {
                    isLoading: true,
                    paging: {} as definitions["v_api.Paging"],
                    list: [] as definitions["do.ReviewDO"][]
                }
            }

            book.reviews.isLoading = true
            state.map[book.detail.id as string] = book
            state.map[book.detail.slug as string] = book
        });
        builder.addCase(getListReviews.fulfilled, (state, action) => {
            console.log("getListReviews fulfilled - meta offset: ", action.meta.arg.offset, ", limit: ", action.meta.arg.limit)
            const bookID = action.meta.arg.bookID;

            let book = state.map[bookID];

            if (!book) {
                book = createNewBookData()
            }

            if (!book.reviews) {
                book.reviews = {
                    isLoading: false,
                    paging: {} as definitions["v_api.Paging"],
                    list: [] as definitions["do.ReviewDO"][]
                }
            }

            book.isLoading = false
            book.reviews.isLoading = false;

            const newReviews = action.payload.data as definitions["do.ReviewDO"][]
            if (!!newReviews?.length) {
                newReviews.forEach(review => {
                    if (!book.reviews.list.find(r => r.id === review.id)) {
                        book.reviews.list.push(review);
                    }
                })

                const userID = action.meta.arg.userID;
                const first = book.reviews.list.find(r => r.author?.id === userID)
                let list = book.reviews.list.filter(r => r.author?.id !== userID)
                if (!!first) {
                    list = [first, ...list]
                }

                book.reviews.list = list
                book.reviews.paging = action.payload.paging as definitions["v_api.Paging"]

                state.map[book.detail.id as string] = book
                state.map[book.detail.slug as string] = book
            }

            console.log("getListReviews fulfilled - bookData: ", JSON.stringify(book))
        });
        builder.addCase(getListReviews.rejected, (state, action) => {
            const bookID = action.meta.arg.bookID;

            const book = state.map[bookID];

            if (!!book) {
                book.isLoading = false
                book.reviews.isLoading = false

                state.map[book.detail.id as string] = book
                state.map[book.detail.slug as string] = book
            }
        });
        builder.addCase(createReview.pending, (state, action) => {
            const bookID = action.meta.arg.bookID;
            let book = state.map[bookID];

            if (!book) {
                book = createNewBookData()
            }

            book.isLoading = true
            state.map[book.detail.id as string] = book
            state.map[book.detail.slug as string] = book
        });
        builder.addCase(createReview.fulfilled, (state, action) => {
            const bookID = action.meta.arg.bookID;

            console.log("createReview fulfilled - Meta: ", action.meta.arg)

            let book = state.map[bookID];

            if (!book) {
                book = createNewBookData()
            }

            if (!book.reviews) {
                book.reviews = {
                    isLoading: false,
                    paging: {} as definitions["v_api.Paging"],
                    list: [] as definitions["do.ReviewDO"][]
                }
            }

            book.isLoading = false
            book.reviews.isLoading = false;

            if (!!book?.detail) {
                book.detail.stats = action.payload.data as definitions["do.StatsDO"]

                state.map[book.detail.id as string] = book
                state.map[book.detail.slug as string] = book
            }

            console.log("createReview fulfilled - done")
        });
        builder.addCase(createReview.rejected, (state, action) => {
            const bookID = action.meta.arg.bookID;

            const book = state.map[bookID];

            if (!!book) {
                book.isLoading = false
                book.reviews.isLoading = false

                state.map[book.detail.id as string] = book
                state.map[book.detail.slug as string] = book
            }
        });

        builder.addCase(updateReview.pending, (state, action) => {
            const bookID = action.meta.arg.bookID;
            let book = state.map[bookID];

            if (!book) {
                book = createNewBookData()
            }

            if (!book.reviews) {
                book.reviews = {
                    isLoading: false,
                    paging: {} as definitions["v_api.Paging"],
                    list: [] as definitions["do.ReviewDO"][]
                }
            }

            book.reviews.isLoading = true
            state.map[book.detail.id as string] = book
            state.map[book.detail.slug as string] = book
        });
        builder.addCase(updateReview.fulfilled, (state, action) => {
            const bookID = action.meta.arg.bookID;
            const reviewID = action.meta.arg.reviewID;

            console.log("updateReviews fulfilled - bookID: ", bookID)
            console.log("updateReviews fulfilled - reviewID: ", reviewID)

            let book = state.map[bookID];

            if (!book) {
                book = createNewBookData()
            }

            if (!book.reviews) {
                book.reviews = {
                    isLoading: false,
                    paging: {} as definitions["v_api.Paging"],
                    list: [] as definitions["do.ReviewDO"][]
                }
            }

            book.reviews.isLoading = false;

            if (!!book?.detail) {
                book.detail.stats = action.payload.data as definitions["do.StatsDO"]

                state.map[book.detail.id as string] = book
                state.map[book.detail.slug as string] = book
            }

            book.reviews.list.map(review => {
                if (review.id === reviewID) {
                    review.content = action.meta.arg.content
                    review.score = action.meta.arg.score
                }
            });
            console.log("updateReviews fulfilled - bookID: ", bookID, "- done")
        });
        builder.addCase(updateReview.rejected, (state, action) => {
            const bookID = action.meta.arg.bookID;

            const book = state.map[bookID];

            if (!!book) {
                book.isLoading = false
                book.reviews.isLoading = false

                state.map[book.detail.id as string] = book
                state.map[book.detail.slug as string] = book
            }
        });

        builder.addCase(deleteReview.pending, (state, action) => {
            const bookID = action.meta.arg.bookID;

            let book = state.map[bookID];

            if (!book) {
                book = createNewBookData()
            }

            if (!book.reviews) {
                book.reviews = {
                    isLoading: false,
                    paging: {} as definitions["v_api.Paging"],
                    list: [] as definitions["do.ReviewDO"][]
                }
            }

            book.reviews.isLoading = true
            state.map[book.detail.id as string] = book
            state.map[book.detail.slug as string] = book
        });
        builder.addCase(deleteReview.fulfilled, (state, action) => {
            const bookID = action.meta.arg.bookID;
            const reviewID = action.meta.arg.reviewID;


            let book = state.map[bookID];

            if (!book) {
                book = createNewBookData()
            }

            if (!book.reviews) {
                book.reviews = {
                    isLoading: false,
                    paging: {} as definitions["v_api.Paging"],
                    list: [] as definitions["do.ReviewDO"][]
                }
            }

            console.log("deleteReview fulfilled - bookID: ", bookID, ", reviewID: ", reviewID)
            book.reviews.isLoading = false;
            console.log("deleteReview fulfilled - bookID: ", bookID, " - done")
            book.reviews.list = book.reviews.list.filter(review => review.id !== reviewID);
            console.log("deleteReview fulfilled - bookID: ", bookID, " - done")

            if (!!book?.detail) {
                book.detail.stats = action.payload.data as definitions["do.StatsDO"]

                state.map[book.detail.id as string] = book
                state.map[book.detail.slug as string] = book
            }

            console.log("deleteReview fulfilled - bookID: ", bookID, " - done")
        });
        builder.addCase(deleteReview.rejected, (state, action) => {
            const bookID = action.meta.arg.bookID;

            const book = state.map[bookID];

            if (!!book) {
                book.isLoading = false
                book.reviews.isLoading = false

                state.map[book.detail.id as string] = book
                state.map[book.detail.slug as string] = book
            }
        });

        // Comment
        builder.addCase(getListComments.pending, (state, action) => {
            const bookID = action.meta.arg.bookID;
            let book = state.map[bookID];

            if (!book) {
                book = createNewBookData()
            }
            if (!book.comments) {
                book.comments = {
                    isLoading: true,
                    paging: {} as definitions["v_api.Paging"],
                    list: [] as definitions["do.CommentDO"][]
                }
            }

            book.comments.isLoading = true
            state.map[book.detail.id as string] = book
            state.map[book.detail.slug as string] = book
        });
        builder.addCase(getListComments.fulfilled, (state, action) => {
            console.log("getListComments fulfilled - meta offset: ", action.meta.arg.offset, ", limit: ", action.meta.arg.limit)
            const bookID = action.meta.arg.bookID;

            let book = state.map[bookID];

            if (!book) {
                book = createNewBookData()
            }

            if (!book.comments) {
                book.comments = {
                    isLoading: false,
                    paging: {} as definitions["v_api.Paging"],
                    list: [] as definitions["do.CommentDO"][]
                }
            }

            book.isLoading = false
            book.comments.isLoading = false;

            const newComments = action.payload.data as definitions["do.CommentDO"][]
            console.log("getListComments fulfilled - new comments: ", JSON.stringify(newComments))

            if (!!newComments?.length) {
                newComments.forEach(comment => {
                    if (!book.comments.list.find(r => r.id === comment.id)) {
                        book.comments.list.push(comment);
                    }
                })

                const userID = action.meta.arg.userID;
                const first = book.comments.list.find(r => r.author?.id === userID)
                let list = book.comments.list.filter(r => r.author?.id !== userID)
                if (!!first) {
                    list = [first, ...list]
                }

                book.comments.list = list
                book.comments.paging = action.payload.paging as definitions["v_api.Paging"]

                state.map[book.detail.id as string] = book
                state.map[book.detail.slug as string] = book
            }
        });
        builder.addCase(getListComments.rejected, (state, action) => {
            const bookID = action.meta.arg.bookID;

            const book = state.map[bookID];

            if (!!book) {
                book.isLoading = false
                book.comments.isLoading = false

                state.map[book.detail.id as string] = book
                state.map[book.detail.slug as string] = book
            }
        });

        builder.addCase(createComment.pending, (state, action) => {
            const bookID = action.meta.arg.bookID;
            let book = state.map[bookID];

            if (!book) {
                book = createNewBookData()
            }

            book.isLoading = true
            state.map[book.detail.id as string] = book
            state.map[book.detail.slug as string] = book
        });
        builder.addCase(createComment.fulfilled, (state, action) => {
            const bookID = action.meta.arg.bookID;

            console.log("createComment fulfilled - Meta: ", action.meta.arg)

            let book = state.map[bookID];

            if (!book) {
                book = createNewBookData()
            }

            if (!book.comments) {
                book.comments = {
                    isLoading: false,
                    paging: {} as definitions["v_api.Paging"],
                    list: [] as definitions["do.CommentDO"][]
                }
            }

            book.isLoading = false
            book.comments.isLoading = false;

            if (!!book?.detail) {
                book.detail.stats = action.payload.data as definitions["do.StatsDO"]

                state.map[book.detail.id as string] = book
                state.map[book.detail.slug as string] = book

                console.log("createComment fulfilled - book updated: ", JSON.stringify(book))
            }

            console.log("createComment fulfilled - done")
        });
        builder.addCase(createComment.rejected, (state, action) => {
            const bookID = action.meta.arg.bookID;

            const book = state.map[bookID];

            if (!!book) {
                book.isLoading = false
                book.comments.isLoading = false

                state.map[book.detail.id as string] = book
                state.map[book.detail.slug as string] = book
            }
        });

        builder.addCase(updateComment.pending, (state, action) => {
            const bookID = action.meta.arg.bookID;
            let book = state.map[bookID];

            if (!book) {
                book = createNewBookData()
            }

            if (!book.comments) {
                book.comments = {
                    isLoading: false,
                    paging: {} as definitions["v_api.Paging"],
                    list: [] as definitions["do.CommentDO"][]
                }
            }

            book.comments.isLoading = true
            state.map[book.detail.id as string] = book
            state.map[book.detail.slug as string] = book
        });
        builder.addCase(updateComment.fulfilled, (state, action) => {
            const bookID = action.meta.arg.bookID;
            const commentID = action.meta.arg.commentID;

            console.log("updateComment fulfilled - bookID: ", bookID)
            console.log("updateComment fulfilled - commentID: ", commentID)

            let book = state.map[bookID];

            if (!book) {
                book = createNewBookData()
            }

            if (!book.comments) {
                book.comments = {
                    isLoading: false,
                    paging: {} as definitions["v_api.Paging"],
                    list: [] as definitions["do.CommentDO"][]
                }
            }

            book.comments.isLoading = false;

            if (!!book?.detail) {
                book.detail.stats = action.payload.data as definitions["do.StatsDO"]

                state.map[book.detail.id as string] = book
                state.map[book.detail.slug as string] = book
            }

            book.comments.list.map(review => {
                if (review.id === commentID) {
                    review.content = action.meta.arg.content
                }
            });
            console.log("updateComment fulfilled - bookID: ", bookID, "- done")
        });
        builder.addCase(updateComment.rejected, (state, action) => {
            const bookID = action.meta.arg.bookID;

            const book = state.map[bookID];

            if (!!book) {
                book.isLoading = false
                book.comments.isLoading = false

                state.map[book.detail.id as string] = book
                state.map[book.detail.slug as string] = book
            }
        });

        builder.addCase(deleteComment.pending, (state, action) => {
            const bookID = action.meta.arg.bookID;

            let book = state.map[bookID];

            if (!book) {
                book = createNewBookData()
            }

            if (!book.comments) {
                book.comments = {
                    isLoading: false,
                    paging: {} as definitions["v_api.Paging"],
                    list: [] as definitions["do.CommentDO"][]
                }
            }

            book.comments.isLoading = true
            state.map[book.detail.id as string] = book
            state.map[book.detail.slug as string] = book
        });
        builder.addCase(deleteComment.fulfilled, (state, action) => {
            const bookID = action.meta.arg.bookID;
            const commentID = action.meta.arg.commentID;


            let book = state.map[bookID];

            if (!book) {
                book = createNewBookData()
            }

            if (!book.comments) {
                book.comments = {
                    isLoading: false,
                    paging: {} as definitions["v_api.Paging"],
                    list: [] as definitions["do.CommentDO"][]
                }
            }

            console.log("deleteComment fulfilled - bookID: ", bookID, ", commentID: ", commentID)
            book.comments.isLoading = false;
            console.log("deleteComment fulfilled - bookID: ", bookID, " - done")
            book.comments.list = book.comments.list.filter(comment => comment.id !== commentID);
            console.log("deleteComment fulfilled - bookID: ", bookID, " - done")

            if (!!book?.detail) {
                book.detail.stats = action.payload.data as definitions["do.StatsDO"]

                state.map[book.detail.id as string] = book
                state.map[book.detail.slug as string] = book
            }

            console.log("deleteComment fulfilled - bookID: ", bookID, " - done")
        });
        builder.addCase(deleteComment.rejected, (state, action) => {
            const bookID = action.meta.arg.bookID;

            const book = state.map[bookID];

            if (!!book) {
                book.isLoading = false
                book.comments.isLoading = false

                state.map[book.detail.id as string] = book
                state.map[book.detail.slug as string] = book
            }
        });

        // User reading
        builder.addCase(getReadingsByBook.pending, (state) => {
        });
        builder.addCase(getReadingsByBook.fulfilled, (state, action) => {
        });
        builder.addCase(getReadingsByBook.rejected, (state, action) => {
        });
    },
});
// setID, setSlug, setDetail, setListChapters, setListComments, setListReadStore, updateChapterIndex, setAllListReads
export const { initialBookStateFromStorage, setBookStateToStorage } = bookMap.actions;

export const bookInfo = (state: RootState) => state.bookReducer;

export default bookMap.reducer;
