import { configureStore } from "@reduxjs/toolkit";
import userReducer, { initialUserState } from "./reducers/user.reducer";
import authorReducer from "./reducers/author.reducer";
import bookReducer from "./reducers/book.reducer";
import themeReducer from "./reducers/theme.reducer";

export const store = configureStore({
    reducer: {
        userReducer,
        authorReducer,
        bookReducer,
        themeReducer,
    },
    preloadedState: {
        userReducer: initialUserState(),
    },
    devTools: process.env.NODE_ENV !== "production",
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
