import { apiCaller } from "./metruyenvl_caller";

const bookAPI = {
    async getDetailByID(id: string) {
        return apiCaller("/book", "get", {
            query: { id: id },
            header: {
                Authorization: "",
            },
        });
    },

    async getDetailBySlug(slug: string) {
        return apiCaller("/book", "get", {
            query: { slug: slug },
            header: {
                Authorization: "",
            },
        });
    },

    async getDetailAndChapter(bookSlug: string, chapterSlug: string) {
        return apiCaller("/book/{book_slug}/chapter/{chapter_slug}", "get", { path: { book_slug: bookSlug, chapter_slug: chapterSlug }, query: {} });
    },

    async getAll({
        status,
        state = "public",
        timeType = "updated_time",
        orderBy = "read_count",
        period = "month",
        sortBy = "desc",
        tag = "",
        withStats = false,
        page = 1,
        pageSize = 20,
    }: {
        status?: "drop" | "writing" | "finish";
        state?: "pre-access" | "public" | "archive";
        timeType?: "updated_time" | "created_time";
        orderBy?: "read_count" | "comments" | "reviews" | "review_point" | "bookmarks" | "react_count" | "suggest_point" | "chapters_count"
        period?: "day" | "week" | "month" | "quarter" | "year" | "total";
        sortBy?: "desc" | "asc";
        tag?: string;
        genre?: string;
        withStats?: boolean;
        page?: number;
        pageSize?: number;
    }) {
        if (status) {
            return apiCaller("/book/all", "get", { query: { status: status, state: state, time_type: timeType, order_by: orderBy, period: period, sort_by: sortBy, tag: tag, with_stats: withStats, page: page, page_size: pageSize } });
        } else {
            return apiCaller("/book/all", "get", { query: { state: state, time_type: timeType, order_by: orderBy, period: period, sort_by: sortBy, tag: tag, with_stats: withStats, page: page, page_size: pageSize } });
        }
    },

    async getAllManga(offset?: number, limit?: number) {
        return apiCaller("/book/all", "get", { query: { offset: offset, limit: limit } });
    },

    async getLatestUpdate() {
        return apiCaller("/book/update-latest", "get");
    },

    async getListChapters(id: string, offset: number, limit: number) {
        return apiCaller("/chapter/book/id/{book_id}", "get", {
            path: {
                book_id: id,
            },
            query: {
                offset: offset,
                limit: limit,
            },
        });
    },

    async getListChaptersBySlug(slug: string, page: number, pageSize: number) {
        return apiCaller("/chapter/book/{book_slug}", "get", {
            path: {
                book_slug: slug,
            },
            query: {
                page: page,
                page_size: pageSize,
            },
        });
    },
};

export default bookAPI;
