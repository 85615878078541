import authorAPI from "@/services/author_api";
import { definitions } from "@/services/metruyenvl";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { createReducerPagingKey } from "./utils";
import authAPI from "@/services/auth_api";

export const getAuthorDetail = createAsyncThunk("author/info", async ({ slug }: { slug: string }) => {
    return await authorAPI.getDetail(slug);
});

export const getAuthorBooks = createAsyncThunk("author/books", async ({ slug, page, pageSize }: { slug: string; page: number; pageSize: number }) => {
    return await authorAPI.getBooks(slug, page, pageSize);
});

export const getConfigBanner = createAsyncThunk("author/banner", async () => {
    return await authAPI.getBanner();
});

const initialState = {
    id: "", // ID
    slug: "", // Slug
    isLoading: false,
    detail: {} as definitions["models.AuthorMD"],
    books: {
        paging: {} as definitions["v_api.Paging"],
        // mapChapters: {}, // key = {pageSize}-{page}:definitions["do.BookDO"][]>
        mapChapters: {} as { [key: string]: definitions["do.BookDO"][] }, // key = {pageSize}-{page}:definitions["do.BookDO"][]>
    },
    listFavorites: [] as definitions["do.UserDO"][], // Danh sách comment
};

const authorSlice = createSlice({
    name: "author",
    initialState,
    reducers: {
        setInfo: (state, action) => {
            state.detail = action.payload;
        },
    },

    extraReducers(builder) {
        builder.addCase(getAuthorDetail.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAuthorDetail.fulfilled, (state, action) => {
            state.isLoading = false;
            const data = action.payload as definitions["models.AuthorMD"];

            state.id = data.id as string;
            state.slug = data.slug as string;
            state.detail = data;
        });
        builder.addCase(getAuthorDetail.rejected, (state, action) => {
            state.isLoading = false;
            state.detail = {};
        });

        builder.addCase(getAuthorBooks.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAuthorBooks.fulfilled, (state, action) => {
            state.isLoading = false;

            const data = action.payload.data as definitions["do.BookDO"][];
            const paging = action.payload.paging as definitions["v_api.Paging"];
            const key = createReducerPagingKey(paging);

            state.books.paging = paging;
            state.books.mapChapters[key] = data;

            // localStorage.setItem(localStorageKey(state.slug), JSON.stringify(state));
        });
        builder.addCase(getAuthorBooks.rejected, (state, action) => {
            state.isLoading = false;
            state.id = "";
            state.slug = "";
            state.detail = {};
        });
    },
});

export const { setInfo } = authorSlice.actions;

export const authorInfo = (state: RootState) => state.authorReducer;

export default authorSlice.reducer;
